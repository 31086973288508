<template>
  <div>
    <div class ="row">
      <c-card title="QR 출력정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <!-- <c-select
              class="modelno"
              :width="12"
              :comboItems="printItem"
              itemText="codeName"
              itemValue="code"
              ui="bootstrap"
              name="selectedItem"
              label=""
              v-model="selectedItem"
            /> -->
            <!-- 인쇄 -->
            <c-btn label="인쇄" type="check" @btnClicked="print" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="row" id="printJS-form">
            <div
              class="col-md-12 wrapprint"
              v-for="(qrcode, index) in popupParam.selectData"
              :key="index"
            >
              <div :class="qrClass">
                <vue-qrcode
                  :value="
                    'https://c-mshms.andami.kr/sop/mim/hazardEquipment?saiContructFacilityId=' +
                      qrcode.saiContructFacilityId
                  "
                  :options="{ width: 200, errorCorrectionLevel: 'L' }"
                  class="canvas"
                >
                </vue-qrcode>
                <span class="facilityStyle">
                  <p class="abc">
                    <b>
                      {{
                        '(' + qrcode.managerNo
                          ? qrcode.managerNo
                          : '' + ')'
                      }}
                    </b>
                    <b>
                      <br />
                      {{ qrcode.facilityName }}
                    </b>
                  </p>
                </span>
              </div>
            </div>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import printJs from 'print-js';
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  /** attributes: name, components, props, data **/
  name: 'hazard-equipment-qr-qrcode',
  components: {
    VueQrcode,
  },
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          selectData: []
        }
      },
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '850px',
        top: '100px',
        param: {},
        closeCallback: null,
      },
      redirectUrl: '',
      component: null,
      tabIndex: 0,
      eduMaster: {
        safEduMstNo: 0, // 교육마스터 번호
      },
      printItem: [],
      selectedItem: 'zt410',
    };
  },
  computed: {
    qrStyle() {
      return this.selectedItem === 'zt420'
        ? 'margin-bottom: 152px !important'
        : null;
    },
    qrClass() {
      return this.selectedItem === 'zt420' ? 'wrap zt420' : 'wrap';
    },
  },
  watch: {},
  /** Vue lifecycle: created, mounted, destroyed, etc **/

  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {},
  beforeDestroy() {},
  /** methods **/
  methods: {
    /** 초기화 관련 함수 **/
    init() {
      //   this.comUrl();
      // var canvas = this.$refs.qrRef.$refs['qrcode-vue'];
      this.printItem = [
        {
          code: 'zt410',
          codeName: 'ZT-410',
        },
        {
          code: 'zt420',
          codeName: 'ZT-420',
        },
      ];
    },
    // comUrl() {
    //   this.$http.url = this.$format(
    //     selectConfig.codeMaster.getSelect.url,
    //     'SAF_FAC_QR_PATH'
    //   );
    //   this.$http.type = 'GET';
    //   this.$http.request(
    //     _result => {
    //       this.redirectUrl =
    //         _result.data[0].codeNm + this.popupParam.safEduMstNo;
    //     },
    //     _error => {
    //       window.getApp.$emit('APP_REQUEST_ERROR', _error);
    //     }
    //   );
    // },
    closePopup() {
      this.$emit('closePopup');
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '25px',
        targetStyles: ['*'],
      });
    },
  },
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 40px 20px 0px;
  width: 700px;
  height: 250px;
  box-sizing: border-box;
  margin-bottom: 161px;
}
.wrapprint:last-child > .wrap {
  margin-bottom: 0px !important;
}
.facilityStyle {
  height: 200px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.abc {
  width: 100%;
  /* height:20px; */
  font-size: 1.5em;
  line-height: 50px;
  display: inline;
  padding-right: 10px;
}
@media print {
  .abc {
    width: 100%;
    /* height:20px; */
    font-size: 1.5em;
    display: inline;
    padding-top: 30px;
  }
}
.modelno {
  float: left;
  margin-right: 20px;
}
.zt420 {
  margin-bottom: 152px;
}
</style>
